import { createRouter, createWebHistory } from 'vue-router';
import TOP from './views/TOP.vue';
import Company from './views/Company.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import Terms from './views/Terms.vue';
import Legal from './views/Legal.vue';

const routes = [
        {
                path: '/',
                name: 'top',
                component: TOP
        },
        {
                path: '/company',
                name: 'company',
                component: Company
        },
        {
                path: '/privacy-policy',
                name: 'privacy-policy',
                component: PrivacyPolicy
        },
        {
                path: '/terms',
                name: 'terms',
                component: Terms
        },
        {
                path: '/legal',
                name: 'legal',
                component: Legal
        },
        {
                path: '/:pathMatch(.*)*', // キャッチオールルートを追加
                redirect: '/'
        }
];

const router = createRouter({
        history: createWebHistory(),
        routes
});

export default router;
