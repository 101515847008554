<template>
        <header>
                <p></p>
        </header>
</template>

<script>
export default {
        name: 'HeaderComponent'
};
</script>

<style>
header {
        text-align: center;
        padding: 20px;
        background-color: #ffffff;
        color: #0b0b0b;
}
</style>

