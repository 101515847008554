<template>
  <div>
    <h1>特定商取引法に基づく表記</h1>
  </div>
</template>

<script>
export default {
  name: 'LegalView'
};
</script>
