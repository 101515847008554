<template>
  <div>
    <h1>利用規約</h1>
  </div>
</template>

<script>
export default {
  name: 'TermsView'
};
</script>
