<template>
        <footer>
                <p>© 2024 menoah, Inc. All rights reserved.</p>
        </footer>
</template>

<script>
export default {
        name: 'FooterComponent'
};
</script>

<style>
footer {
        text-align: center;
        padding: 20px;
        background-color: #ffffff;
        color: #000000;
}
nav {
        margin-bottom: 20px;
}
nav a {
        margin-right: 10px;
        text-decoration: none;
        color: #060d0a;
}
</style>
